import React from "react"
import "../css/success.scss"
import Layout from "../components/layout/layout"
import { GlobalHead } from "../helpers/helpers"

function PageContent() {
    return (
        <div>
            <h1>Thanks for your message!</h1>
            <a className="link" href="/about/">
                Back to about page
            </a>
        </div>
    )
}

export function Head() {
    return <GlobalHead></GlobalHead>
}

export default function Success() {
    return <Layout>{PageContent()}</Layout>
}
